/* eslint-disable react-hooks/exhaustive-deps */
/* libraries */
import React, { useEffect, useState } from "react";
import styled from "styled-components";

/* styles */
import sharedStyles from "./sharedStyles";
import { colors } from "../../../../config";

/* components */
import { Button } from "../../../../components/shared";

/* helpers */
import { useSubmissionsContext } from "..";

const WAIT_TIME = 10;

const styles = {
  TopBar: styled.div<{ activePage: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: ${({ activePage }) => (activePage ? "100%" : "0%")};
    height: 10px;
    background-color: ${colors.primary};
    transition: ${WAIT_TIME}s;
  `,
};

const Four = () => {
  const { resetForm, submissions } = useSubmissionsContext();
  const { type } = submissions;

  const [barActive, setBarActive] = useState(false);

  useEffect(() => {
    const activeBarTo = setTimeout(() => {
      setBarActive(true);
    }, 100);
    const resetFormTo = setTimeout(() => {
      resetForm();
    }, WAIT_TIME * 1000);
    return () => {
      clearTimeout(activeBarTo);
      clearTimeout(resetFormTo);
    };
  }, []);

  const submissionText = {
    prayer: "prayer request",
    praise: "praise report",
  };

  return (
    <sharedStyles.Page>
      <styles.TopBar activePage={barActive} />
      <sharedStyles.Title>Thank you</sharedStyles.Title>
      <sharedStyles.Subtitle>
        Thank you for your {submissionText[type]}. We are praying and believing
        with you.
      </sharedStyles.Subtitle>
      <Button
        label="back to start"
        bgColor={colors.primary}
        textColor={colors.white}
        onClick={resetForm}
      />
    </sharedStyles.Page>
  );
};

export default Four;
