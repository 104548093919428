/* libraries */
import { useState } from "react";
import { Form } from "informed";
import { useParams } from "react-router-dom";

/* styles */
import { colors } from "../../../../../config";
import sharedStyles from "../sharedStyles";

/* components */
import { FormInputs, Button } from "../../../../shared";

/* helpers */
import { useSubmissionsContext } from "../..";

const { LongText } = FormInputs;

interface FreeTextProps {
  goToGrid: () => void;
}

const FreeText = ({ goToGrid }: FreeTextProps) => {
  const { type } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);
  const { dispatch, submitPrayerRequest, submissions } =
    useSubmissionsContext();
  const { type: postType } = submissions;

  const onFormChange = (form: any) => {
    const notPristine = !form?.pristine;
    const valid = form?.valid;
    setIsFormValid(notPristine && valid);
  };

  const onFormSubmit = (form: any) => {
    const post = form?.values?.post;
    console.log(">>>>> form vals", post);
    !!post && dispatch({ type: "setPost", payload: post });
    !!post && submitPrayerRequest(post);
  };

  const buttonTheme = {
    bgColor: isFormValid ? colors.primary : colors.lighterGray,
    textColor: isFormValid ? colors.white : colors.black,
  };

  const submissionText = {
    prayer: "praying",
    praise: "praising",
  };

  return (
    <sharedStyles.Page>
      <sharedStyles.Title>
        I'm {submissionText[postType]} for
      </sharedStyles.Title>
      <Form onValueChange={onFormChange} onSubmit={onFormSubmit}>
        <LongText
          label="Type your own prayer request"
          fieldName="post"
          required
        />
        <Button
          label="next"
          bgColor={buttonTheme.bgColor}
          textColor={buttonTheme.textColor}
        />
        {type !== "mobile" && (
          <sharedStyles.GhostButton onClick={goToGrid}>
            Cancel
          </sharedStyles.GhostButton>
        )}
      </Form>
    </sharedStyles.Page>
  );
};

export default FreeText;
