import React from "react";
import styled from "styled-components";

/* helpers */
import { colors } from "../../config";

const styles = {
  Button: styled.button<{
    bgColor?: string;
    textColor?: string;
    extraStyles?: string;
  }>`
    margin-top: 40px;
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 17px;
    min-width: 220px;
    border-radius: 50px;
    transition: background-color 0.5s;
    background-color: ${(p) => p.bgColor || colors.lighterGray};
    outline: none;
    border: none;
    color: ${(p) => p.textColor || colors.black};
    ${(p) => p.extraStyles};
  `,
};

interface ButtonProps {
  bgColor?: string;
  textColor?: string;
  onClick?: () => void;
  label?: string;
  extraStyles?: string;
  children?: JSX.Element | JSX.Element[];
}

const Button = ({
  bgColor,
  textColor,
  onClick,
  label,
  extraStyles,
  children,
}: ButtonProps) => {
  const _onClick = onClick || (() => {});
  return (
    <styles.Button
      bgColor={bgColor}
      textColor={textColor}
      onClick={_onClick}
      extraStyles={extraStyles}>
      {label}
      {children}
    </styles.Button>
  );
};

export default Button;
