import { Input, TextArea } from "informed";
import styled from "styled-components";

/* helpers */
import { colors } from "../../../config";

const Container = styled.div`
  margin-top: 15px;

  input,
  textarea {
    border: 1px solid ${colors.black};
    min-width: 80vw;
    border-radius: 3px;
    padding: 15px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.black};
    display: block;
    margin-top: 10px;
    @media (min-width: 600px) {
      min-width: 600px;
    }
  }
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  textarea {
    height: 120px;
  }
`;

const emailRegex = /\S+@\S+\.\S+/;
const emailValidation = (value: unknown) =>
  typeof value === "string" && emailRegex.test(value)
    ? undefined
    : "Please enter a valid email";

export const Email = () => (
  <Container>
    <Input
      name="email"
      validate={emailValidation}
      validateOn="change-blur"
      label="Email"
      type="email"
      required
    />
  </Container>
);

export const Password = () => (
  <Container>
    <Input name="password" placeholder="Password" type="password" required />
  </Container>
);

interface GenericInputProps {
  label: string;
  fieldName: string;
  required: boolean;
  placeholder?: string;
}
export const Text = ({
  label,
  fieldName,
  required = false,
  placeholder,
}: GenericInputProps) => (
  <Container>
    <Input
      label={label}
      name={fieldName}
      placeholder={placeholder}
      type="text"
      required={required}
    />
  </Container>
);

const longTextValidation = (value: unknown) =>
  typeof value === "string" && value.length > 1
    ? undefined
    : "Please fill the form";

interface TextAreaProps extends GenericInputProps {
  maxLength?: number;
}
export const LongText = ({
  label,
  fieldName,
  required = false,
  maxLength = 340,
  placeholder,
}: TextAreaProps) => (
  <Container>
    <TextArea
      label={label}
      name={fieldName}
      placeholder={placeholder}
      type="text"
      required={required}
      maxLength={maxLength}
      validate={longTextValidation}
      validateOn="change-blur"
    />
  </Container>
);
