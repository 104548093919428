const prayerItems = [
  "Healing",
  "My family",
  "Financial provision",
  "Breakthrough",
  "Wisdom",
  "Relationships",
  "My studies",
  "A miracle",
  "Intimacy with God",
  "Salvation",
  "Peace",
  "Work",
  "Visas",
  "Addiction",
];

export default prayerItems;
