/* libs */
import {
  useReducer,
  createContext,
  Dispatch,
  useContext,
  useState,
} from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

/* types */
import { Submission, SubmitPrayerRequest, Actions } from "./types";
import { httpsCallable } from "firebase/functions";

/* components */
import One from "./pages/One";
import Two from "./pages/Two";
import Three from "./pages/Three";
import Four from "./pages/Four";
import Loader from "../../shared/Loader";

/* helpers */
import { functions } from "../../../firebase";

const DEFAULT_SOURCE = "PUBLIC_SUBMISSION";

const styles = {
  Viewport: styled.div`
    position: fixed;
    inset: 0;
    overflow: hidden;
  `,
  Ribbon: styled.div<{
    currentPage: number;
  }>`
    height: 100vh;
    width: 400vw;
    display: flex;
    transition: 0.5s all;
    transform: translateX(-${(p) => p.currentPage * 100}vw);
  `,
  Item: styled.div`
    flex: 1;
  `,
};

const submitPrayerRequest = async ({
  name,
  email,
  type,
  post,
  source,
}: SubmitPrayerRequest) => {
  const submit = httpsCallable(functions, "submitPublicPrayer");
  await submit({
    prayerRequest: post,
    isAnonymous: false,
    country: "AU",
    type,
    name,
    email,
    source,
  });
};

export const PagerContext = createContext<{
  goToPage: (pageNumber: number) => void;
  currentPage: number;
}>({
  goToPage: (pageNumber: number) => {},
  currentPage: 0,
});

export const usePager = () => {
  const context = useContext(PagerContext);
  return context;
};

const initialState: Submission = {
  email: "",
  name: "",
  post: "",
  type: "prayer",
};

const SubmissionsContext = createContext<{
  submissions: Submission;
  dispatch: Dispatch<Actions>;
  submitPrayerRequest: (post?: string) => void;
  resetForm: () => void;
}>({
  submissions: initialState,
  dispatch: () => {},
  submitPrayerRequest: () => {},
  resetForm: () => {},
});

export const useSubmissionsContext = () => {
  const context = useContext(SubmissionsContext);
  return context;
};

const reducer = (state: Submission, action: Actions) => {
  switch (action.type) {
    case "setEmail":
      return { ...state, email: action.payload };
    case "setName":
      return { ...state, name: action.payload };
    case "setPost":
      return { ...state, post: action.payload };
    case "setType":
      return { ...state, type: action.payload };
    case "reset":
      return initialState;
    default:
      return state;
  }
};

const Submit = () => {
  const { source } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const goToPage = (page: number) => setPage(Math.min(Math.max(page, 0), 3));

  const resetForm = () => {
    dispatch({ type: "reset" });
    setPage(0);
  };

  const performSubmission = async (post?: string) => {
    setLoading(true);
    await submitPrayerRequest({
      name: state.name,
      post: post || state.post,
      type: state.type,
      email: state.email,
      source: source || DEFAULT_SOURCE,
    });
    goToPage(3);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <PagerContext.Provider value={{ goToPage, currentPage: page }}>
      <SubmissionsContext.Provider
        value={{
          submissions: state,
          dispatch,
          submitPrayerRequest: performSubmission,
          resetForm,
        }}>
        <styles.Viewport>
          <styles.Ribbon currentPage={page}>
            <styles.Item>{page === 0 && <One />}</styles.Item>
            <styles.Item>{page === 1 && <Two />}</styles.Item>
            <styles.Item>{page === 2 && <Three />}</styles.Item>
            <styles.Item>{page === 3 && <Four />}</styles.Item>
          </styles.Ribbon>
        </styles.Viewport>
        <Loader isVisible={loading} />
      </SubmissionsContext.Provider>
    </PagerContext.Provider>
  );
};

export default Submit;
