/* libs */
import React from "react";
import styled, { keyframes } from "styled-components";

/* config */
import { colors } from "../../config";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styles = {
  Container: styled.div<{
    isVisible: boolean;
  }>`
    position: fixed;
    inset: 0;
    transition: 0.5s all;
    transform: translateY(${(p) => (p.isVisible ? 0 : 100)}%);
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Loader: styled.div`
    border: 16px solid ${colors.lighterGray};
    border-top: 16px solid ${colors.primary};
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: ${rotate} 2s linear infinite;
    position: absolute;
  `,
};

interface LoaderProps {
  isVisible: boolean;
}

const Loader = ({ isVisible }: LoaderProps) => {
  return (
    <styles.Container isVisible={isVisible}>
      <styles.Loader />
    </styles.Container>
  );
};

export default Loader;
