import React from "react";
import { PrayerWallProvider, PrayerWall } from "@invorto/prayer-wall";
import { functions, db } from "../../../firebase";
import { colors } from "../../../config";

const FullPrayerWall = () => {
  return (
    <PrayerWallProvider
      db={db}
      functions={functions}
      createAccountPath="/signup"
      theme={{
        wall: {
          backgroundColor: colors.white,
        },
        prayerRequest: {
          praiseColor: colors.primary,
          prayerColor: colors.secondary,
        },
        notificationsCentre: {
          backgroundColor: colors.white,
          textColor: colors.black,
        },
        notification: {
          backgroundColor: colors.black,
          textColor: colors.white,
        },
      }}
      supportMessage={`<p>For additional support visit <a href="https://colourconference.com/help">colourconference.com/help</a></p>`}>
      <PrayerWall hideInput={true} />
    </PrayerWallProvider>
  );
};

export default FullPrayerWall;
