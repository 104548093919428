/* libraries */
import styled from "styled-components";

/* helpers */
import { colors } from "../../../../config";

const sharedStyles = {
  Page: styled.div<{ bgColor?: string }>`
    background-color: ${(p) => p.bgColor || colors.white};
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    form {
      display: flex;
      width: 100%;
      display: block;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,
  Title: styled.h1`
    font-weight: 900;
    font-size: 42px;
    line-height: 1.2;
    text-align: center;
    color: ${(p) => p.color || colors.black};
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    width: 80%;
    @media (min-width: 600px) {
      min-width: 600px;
      font-size: 60px;
    }
  `,
  Subtitle: styled.h2`
    font-size: 28px;
    line-height: 1.2;
    font-weight: normal;
    max-width: 500px;
    text-align: center;
    color: ${(p) => p.color || colors.black};
  `,
  GhostButton: styled.button`
    margin-top: 42px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: ${colors.black};
    background: none;
    border: none;
  `,
  BackButton: styled.button`
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 22px;
    font-weight: normal;
    color: ${colors.black};
    background: none;
    border: none;
  `,
};

export default sharedStyles;
