// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

// CHURCH
export const firebaseApp = initializeApp({
  apiKey: "AIzaSyBJATNKJ30DRfksEKkqas2yM3KtQ03aJ8A",
  authDomain: "hsc-22-51d22.firebaseapp.com",
  projectId: "hsc-22-51d22",
  storageBucket: "hsc-22-51d22.appspot.com",
  messagingSenderId: "552992767976",
  appId: "1:552992767976:web:807e068ba7944739c3ae5d",
});

export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const db = getFirestore(firebaseApp);
// connectFunctionsEmulator(functions, "localhost", 5001);
