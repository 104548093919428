/* libraries */
import styled from "styled-components";
import { PencilSquare } from "@styled-icons/bootstrap/PencilSquare";

/* styles */
import { colors } from "../../../../../config";
import sharedStyles from "../sharedStyles";

/* components */
import { Button } from "../../../../../components/shared";

/* helpers */
import prayerItems from "./prayerItems";
import { useSubmissionsContext } from "../..";

const getBoxShadows = (selected: boolean) =>
  selected
    ? `inset 0px 4px 4px rgba(0, 0, 0, 0.3)`
    : `4px 4px 6px rgba(0, 0, 0, 0.1)`;

const styles = {
  Post: styled.h1`
    font-family: adobe-handwriting-tiffany, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: ${colors.black};
    font-style: normal;
    font-size: 60px;
    line-height: 1.5;
    font-weight: 400;
    border-bottom: 3px solid ${colors.black};
    min-width: 600px;
    padding: 0 0;
    vertical-align: baseline;
    height: 60px;
  `,
  Grid: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    width: 600px;
  `,
  Item: styled.button<{ selected?: boolean }>`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    border-radius: 0;
    padding: 19px 0;
    transition: 0.015s all;
    border: none;
    outline: none;
    border-radius: 8px;
    box-shadow: ${(p) => getBoxShadows(p.selected || false)};
    color: ${(p) => (p.selected ? colors.white : colors.black)};
    background-color: ${(p) =>
      p.selected ? colors.primary : colors.lighterGray};
  `,
  EditIcon: styled(PencilSquare)`
    margin-left: 10px;
  `,
};

interface PrayerGridProps {
  goToFreeText: () => void;
}

const PrayerGrid = ({ goToFreeText }: PrayerGridProps) => {
  const { submissions, dispatch, submitPrayerRequest } =
    useSubmissionsContext();

  const { post, type } = submissions;
  const setPost = (post: string) => {
    dispatch({ type: "setPost", payload: post });
  };

  const onNextClick = () => submitPrayerRequest();

  const isSelectedPost = prayerItems.includes(post);

  const buttonTheme = {
    bgColor: isSelectedPost ? colors.primary : colors.lighterGray,
    textColor: isSelectedPost ? colors.white : colors.black,
  };

  const submissionText = {
    prayer: "praying",
    praise: "praising",
  };

  return (
    <sharedStyles.Page>
      <sharedStyles.Title>I'm {submissionText[type]} for</sharedStyles.Title>
      <styles.Post>{isSelectedPost ? post : ""}</styles.Post>
      <styles.Grid>
        {prayerItems.map((item, index) => (
          <styles.Item
            key={index}
            selected={post === item}
            onClick={() => setPost(item)}>
            {item}
          </styles.Item>
        ))}
      </styles.Grid>
      <sharedStyles.GhostButton onClick={goToFreeText}>
        Write my own <styles.EditIcon color={colors.black} size="20" />
      </sharedStyles.GhostButton>
      <Button
        label="next"
        bgColor={buttonTheme.bgColor}
        textColor={buttonTheme.textColor}
        onClick={onNextClick}
      />
    </sharedStyles.Page>
  );
};

export default PrayerGrid;
