/* libraries */
import React from "react";
import styled from "styled-components";

/* styles */
import { colors } from "../../../../config";
import sharedStyles from "./sharedStyles";

/* helpers */
import { useSubmissionsContext, usePager } from "..";

/* types */
import { PostType } from "../types";

const styles = {
  Button: styled.button<{ bgColor: string; color: string }>`
    background-color: ${(p) => p.bgColor};
    border: none;
    color: ${(p) => p.color};
    border-radius: 100px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 27px;
    width: 80%;
    padding: 20px 0;
    margin-top: 20px;
    @media (min-width: 600px) {
      margin-top: 30px;
      width: 500px;
      height: 150px;
      font-size: 36px;
    }
  `,
};

const One = () => {
  const { dispatch } = useSubmissionsContext();
  const { goToPage } = usePager();

  const setPrayerType = (type: PostType) => {
    dispatch({ type: "setType", payload: type });
    goToPage(1);
  };

  return (
    <sharedStyles.Page bgColor={colors.black}>
      <sharedStyles.Title color={colors.white}>
        We are a <br /> praying community
      </sharedStyles.Title>
      <styles.Button
        bgColor={colors.secondary}
        color={colors.black}
        onClick={() => setPrayerType("prayer")}>
        PRAYER REQUEST
      </styles.Button>
      <styles.Button
        bgColor={colors.primary}
        color={colors.white}
        onClick={() => setPrayerType("praise")}>
        PRAISE REPORT
      </styles.Button>
    </sharedStyles.Page>
  );
};

export default One;
