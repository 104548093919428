import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PrayerGrid from "./PrayerGrid";
import FreeText from "./FreeText";

const Three = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { type } = useParams();

  const goToGrid = () => setCurrentPage(0);
  const goToFreeText = () => setCurrentPage(1);

  useEffect(() => {
    type === "mobile" && goToFreeText();
  }, [type]);

  return (
    <>
      {currentPage === 0 && <PrayerGrid goToFreeText={goToFreeText} />}
      {currentPage === 1 && <FreeText goToGrid={goToGrid} />}
    </>
  );
};

export default Three;
