export const colors = {
  primary: "#DD5336",
  secondary: "#FDD5A1",
  black: "#000",
  white: "#FFF",
  noSoWhite: "#FBFBE5",
  gray: "#555",
  lightGray: "#BBB",
  lighterGray: "#f2f2f2",
  lightestGray: "#f5f5f5",
  error: "#FF0044",
};
