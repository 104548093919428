/* libraries */
import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "informed";

/* helpers */
import { useSubmissionsContext, usePager } from "..";

/* styles */
import sharedStyles from "./sharedStyles";
import { colors } from "../../../../config";

/* components */
import { Button, FormInputs } from "../../../../components/shared";

const { Email, Text } = FormInputs;

const styles = {
  ButtonContainer: styled.div`
    width: 220px;
    align-self: center;
  `,
};

const Two = () => {
  const { dispatch, submissions } = useSubmissionsContext();
  const { type } = submissions;
  const { goToPage } = usePager();
  const [isFormValid, setIsFormValid] = useState(false);

  const onFormSubmit = (form: any) => {
    const email = form?.values?.email;
    const name = form?.values?.name;
    !!email && dispatch({ type: "setEmail", payload: email });
    !!name && dispatch({ type: "setName", payload: name });
    goToPage(2);
  };

  const onFormChange = (form: any) => {
    const notPristine = !form?.pristine;
    const valid = form?.valid;
    setIsFormValid(notPristine && valid);
  };

  const buttonThemes = {
    text: isFormValid ? colors.white : colors.black,
    bg: isFormValid ? colors.primary : colors.lighterGray,
  };

  const submissionText = {
    prayer: "praying",
    praise: "praising",
  };

  const onBackClick = () => goToPage(0);

  return (
    <sharedStyles.Page>
      <sharedStyles.BackButton onClick={onBackClick}>
        BACK
      </sharedStyles.BackButton>
      <sharedStyles.Title>
        We are a <br /> {submissionText[type]} community
      </sharedStyles.Title>
      <Form onSubmit={onFormSubmit} onValueChange={onFormChange}>
        <Text label="Name" fieldName="name" required={false} />
        <Email />
        <styles.ButtonContainer>
          <Button
            label="next"
            bgColor={buttonThemes.bg}
            textColor={buttonThemes.text}
          />
        </styles.ButtonContainer>
      </Form>
    </sharedStyles.Page>
  );
};

export default Two;
